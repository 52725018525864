<template @lo>
  <div class="m-2">
    <b-card title="Base Info">
      <b-row v-if="info != null">
        <b-col md="4">
          <dl class="row">
            <dt class="col-sm-4 text-right">
              ID NO. :
            </dt>
            <dd class="col-sm-4">
              {{ info.id_no }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4 text-right">
              ID Type :
            </dt>
            <dd class="col-sm-4">
              {{ info.id_type }}
            </dd>
          </dl>
        </b-col>
        <b-col md="4">
          <dl class="row">
            <dt class="col-sm-4 text-right">
              Name :
            </dt>
            <dd class="col-sm-8">
              {{ info.user_name }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4 text-right">
              Sex :
            </dt>
            <dd class="col-sm-8">
              {{ info.gender }}
            </dd>
          </dl>
        </b-col>
        <b-col md="4">
          <dl class="row">
            <dt class="col-sm-4 text-right">
              Birthday :
            </dt>
            <dd class="col-sm-8">
              {{ info.birthday }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4 text-right">
              Mother Name :
            </dt>
            <dd class="col-sm-8">
              {{ info.mother_name }}
            </dd>
          </dl>
        </b-col>
      </b-row>
    </b-card>

    <b-card title="Individual Details">
      <b-card title="Basic Info">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="3">
              <b-form-group
                label="Full Name"
                label-for="mc-full-name"
              >
                <b-form-input
                  id="mc-full-name"
                  v-model="individualDetails.full_name"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="CPF"
                label-for="mc-cpf"
              >
                <b-form-input
                  id="mc-cpf"
                  v-model="individualDetails.cpf"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="ID Type"
                label-for="mc-id-type"
              >
                <b-form-input
                  id="mc-id-type"
                  value="CPF"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Country"
                label-for="mc-country"
              >
                <b-form-input
                  id="mc-country"
                  v-model="individualDetails.country"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="CPF Verify"
                label-for="mc-cpf-verify"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="mc-cpf-verify"
                    v-model="individualDetails.cpf_verify"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Date of birth"
                label-for="mc-date-of-birth"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="mc-date-of-birth"
                    v-model="individualDetails.date_of_birth"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label-for="mc-alive"
                label="Alive"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="mc-alive"
                    v-model="individualDetails.alive"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label-for="mc-gender"
                label="Gender"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="mc-gender"
                    v-model="individualDetails.gender"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <b-form v-for="item in individualDetails.address_list" :key="item.name">
          <b-row>
            <b-col md="2">
              <b-form-group label-for="mc-building" label="Building">
                <div class="form-label-group">
                  <b-form-input
                    id="mc-building"
                    v-model="item.building"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label-for="mc-street" label="Street">
                <div class="form-label-group">
                  <b-form-input
                    id="mc-street"
                    v-model="item.street"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label-for="mc-city" label="City">
                <div class="form-label-group">
                  <b-form-input
                    id="mc-city"
                    v-model="item.city"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label-for="mc-state" label="State">
                <div class="form-label-group">
                  <b-form-input
                    id="mc-state"
                    v-model="item.state"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label-for="mc-country" label="Country">
                <div class="form-label-group">
                  <b-form-input
                    id="mc-country"
                    value="Brazil"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label-for="mc-zipcode"
                label="Zip Code"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="mc-zipcode"
                    v-model="individualDetails.zip_code"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <b-form>
          <b-row>
            <b-col md="3">
              <b-form-group
                label-for="mc-contact-email"
                label="Contact Email"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="mc-contact-email"
                    v-model="individualDetails.contact_email"
                    type="email"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label-for="mc-cpf-registratiion-status"
                label="CPF Registration Status"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="mc-cpf-registration-status"
                    v-model="individualDetails.cpf_registration_status"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label-for="mc-income-tax-status"
                label="Income Tax Status"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="mc-income-tax-status"
                    v-model="individualDetails.income_tax_status"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label-for="mc-income-level"
                label="Income Level"
              >
                <div class="form-label-group">
                  <b-form-input
                    id="mc-income-level"
                    v-model="individualDetails.income_level"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
      <b-card title="Telephones">
        <b-form>
          <b-row v-for="item in individualDetails.tel_list" :key="item.phone_num">
            <b-col md="4">
              <b-form-group label="Telephone:">
                <div class="form-label-group">
                  <b-form-input
                    v-model="item.phone_num"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Tipo Assinante:">
                <div class="form-label-group">
                  <b-form-input
                    v-model="item.tipo_assinante"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="People with same phone:">
                <div class="form-label-group">
                  <b-form-input
                    v-model="item.same_phone_num"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Principal:">
                <div class="form-label-group">
                  <b-form-input
                    v-model="item.principal"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
      <b-card title="Relatives">
        <b-form>
          <b-row v-for="item in individualDetails.relative_list" :key="item.name">
            <b-col md="6">
              <b-form-group label="Name:">
                <div class="form-label-group">
                  <b-form-input
                    v-model="item.name"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="RelationShip Type">
                <div class="form-label-group">
                  <b-form-input
                    v-model="item.relationship_type"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
      <b-card title="Business Affiliations">
        <b-form>
          <b-row v-for="item in individualDetails.business_relative_list" :key="item.name">
            <b-col md="6">
              <b-form-group label="Company Name:">
                <div class="form-label-group">
                  <b-form-input
                    v-model="item.company_name"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Type:">
                <div class="form-label-group">
                  <b-form-input
                    v-model="item.id_type"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Relationship">
                <div class="form-label-group">
                  <b-form-input
                    v-model="item.relationship"
                    readonly
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-card>

    <b-card title="Sanctions">
      <b-table
        class="position-relative"
        responsive
        :fields="detailSanctionsTableFields"
        :items="sanctionRecords"
        primary-key="uid"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >
        <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            class="vs-checkbox-con"
            @change="row.toggleDetails"
          >
            <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
          </b-form-checkbox>
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <b-card title="Details">
            <b-row>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>UID : </strong>{{ row.item.uid }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>First Name : </strong>{{ row.item.first_name }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>Last Name : </strong>{{ row.item.last_name }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>Title : </strong>{{ row.item.title }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>Type : </strong>{{ row.item.sdn_type }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>Remarks : </strong>{{ row.item.remarks }}
              </b-col>
            </b-row>
          </b-card>

          <b-card
            v-if="row.item.program_list"
            title="Propram(s)"
            class="mt-2"
          >
            <b-row>
              <b-col
                v-for="item in row.item.program_list"
                :key="item.index"
                md="4"
                class="mb-1"
              >
                {{ item }}
              </b-col>
            </b-row>
          </b-card>

          <b-card
            v-if="row.item.id_list"
            title="ID(s)"
            class="mt-2"
          >
            <b-table
              class="position-relative"
              responsive
              :fields="idsTableFields"
              :items="row.item.id_list"
              primary-key="uid"
              show-empty
              striped
              :borderless="false"
              empty-text="No matching records found"
            />
          </b-card>

          <b-card
            v-if="row.item.aka_list"
            title="A.K.A."
            class="mt-2"
          >
            <b-table
              class="position-relative"
              responsive
              :fields="akaTableFields"
              :items="row.item.aka_list"
              primary-key="uid"
              show-empty
              striped
              :borderless="false"
              empty-text="No matching records found"
            />
          </b-card>

          <b-card
            v-if="row.item.address_list"
            title="Address(s)"
            class="mt-2"
          >
            <b-table
              class="position-relative"
              responsive
              :fields="addressTableFields"
              :items="row.item.address_list"
              primary-key="uid"
              show-empty
              striped
              :borderless="false"
              empty-text="No matching records found"
            />
          </b-card>

          <b-card
            v-if="row.item.nationality_list"
            title="Nationality(s)"
            class="mt-2"
          >
            <b-table
              class="position-relative"
              responsive
              :fields="nationalityTableFields"
              :items="row.item.nationality_list"
              primary-key="uid"
              show-empty
              striped
              :borderless="false"
              empty-text="No matching records found"
            />
          </b-card>

          <b-card
            v-if="row.item.citizenship_list"
            title="Citizenship(s)"
            class="mt-2"
          >
            <b-table
              class="position-relative"
              responsive
              :fields="citizenshipTableFields"
              :items="row.item.citizenship_list"
              primary-key="uid"
              show-empty
              striped
              :borderless="false"
              empty-text="No matching records found"
            />
          </b-card>

          <b-card
            v-if="row.item.date_of_birth_list"
            title="DateOfBirth"
            class="mt-2"
          >
            <b-table
              class="position-relative"
              responsive
              :fields="dateBirthTableFields"
              :items="row.item.date_of_birth_list"
              primary-key="uid"
              show-empty
              striped
              :borderless="false"
              empty-text="No matching records found"
            />
          </b-card>

          <b-card
            v-if="row.item.place_of_birth_list"
            title="PlaceOfBirth"
            class="mt-2"
          >
            <b-table
              class="position-relative"
              responsive
              :fields="placeBirthTableFields"
              :items="row.item.place_of_birth_list"
              primary-key="uid"
              show-empty
              striped
              :borderless="false"
              empty-text="No matching records found"
            />
          </b-card>

          <b-card
            v-if="row.item.vessel_info"
            title="VesselInfo"
            class="mt-2"
          >
            <b-row>
              <b-col
                v-for="(value, key) in row.item.vessel_info"
                :key="key"
                md="4"
                class="mb-1"
              >
                <strong>{{ key }} : </strong>{{ value }}
              </b-col>
            </b-row>
          </b-card>
        </template>

        <!-- Column: Actions -->
        <template v-if="sanctionBtnShow" #cell(actions)="row">
          <div class="text-nowrap">
            <feather-icon
              icon="TargetIcon"
              size="16"
              class="cursor-pointer mx-1"
              @click="confirmSanction(row.item, true)"
            />
          </div>
        </template>
      </b-table>
      <b-col v-if="sanctionBtnShow" md="5" class="mb-md-0 mb-2">
        <b-button class="mt-2 mr-3" variant="primary" @click="confirmSanction(null, false)">
          No Sanction
        </b-button>
        <!-- <b-button class="mt-2" variant="outline-primary" @click="download">
          Download
        </b-button> -->
      </b-col>
    </b-card>

    <b-card title="History Transaction">
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Date Range</label>
          <date-picker
            v-model="dateRange"
            value-type="format"
            format="YYYY-MM-DD HH:mm:ss"
            type="datetime"
            range
            confirm
            :clearable="false"
            :shortcuts="shortcuts"
          />
        </b-col>
        <b-col
          md="8"
          class="mb-md-0 mb-2"
        >
          <b-button
            class="mt-2 mr-3"
            variant="primary"
            @click="search"
          >
            Search
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          md="12"
          class="mb-md-0 mb-2"
        >
          <b-table
            class="position-relative"
            responsive
            :fields="transactionsTableFields"
            :items="records"
            primary-key="trade_no"
            show-empty
            striped
            :borderless="false"
            empty-text="No matching records found"
          >
            <template #cell(trade_no)="data">
              <b-link
                :to="{ name: 'transactions-detail', query:{trade_no: data.item.trade_no, app_id: data.item.app_id , out_trade_no: data.item.out_trade_no } }"
                target="_blank"
                class="font-weight-bold d-block"
              >
                {{ data.item.trade_no }}
              </b-link>
            </template>

            <template #cell(merchant)="data">
              {{ data.item.merchant_name }}
              <small class="text-muted">@{{ data.item.merchant_no }}</small>
            </template>

            <template #cell(app)="data">
              {{ data.item.app_name }}
              <small class="text-muted">@{{ data.item.app_id }}</small>
            </template>

            <template #cell(order_amount)="data">
              {{ data.item.order_currency }} {{ data.item.order_amount }}
            </template>

            <template #cell(pay_amount)="data">
              {{ data.item.pay_currency }} {{ data.item.pay_amount }}
            </template>
            <template #cell(trade_status)="data">
              <b-badge
                pill
                :variant="`light-${resolveStatusVariant(data.item.trade_status)}`"
              >
                {{ data.item.trade_status }}
              </b-badge>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BCard, BRow, BCol, BButton, BTable, BLink, BForm, BFormGroup, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment-timezone'
import amlIdListJs from './amlIdList'

const {
  fetchIdInfo,
  fetchOrderIdInfoList,
  confirmSanction,
} = amlIdListJs()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    DatePicker,
  },
  data() {
    return {
      searchRequest: {
        id_no: this.$route.query.id_no,
        id_type: this.$route.query.id_type,
      },
      records: [],
      info: {},
      sanctionBtnShow: true,
      individualDetails: {},
      individualBasic: {},
      createRange: [],
      tableFields: [
        { key: 'sourceType', label: 'Verify Type' },
        { key: 'status', label: 'Status' },
        { key: 'idNo', label: 'ID NO.' },
        { key: 'idType', label: 'ID Type' },
        { key: 'name', label: 'CPF Name' },
        { key: 'motherName', label: 'CPF-Name Of Mom' },
        { key: 'createTime', label: 'Verify Time' },
        { key: 'remark', label: 'Remarks' },
      ],
      transactionsTableFields: [
        { key: 'trade_no', label: 'TRANSACTION ID' },
        { key: 'out_trade_no', label: 'MERCHANT REFERENCE' },
        'merchant',
        'app',
        { key: 'create_time', label: 'ORDER TIME' },
        { key: 'order_amount', label: 'ORDER AMOUNT' },
        { key: 'pay_amount', label: 'PAY AMOUNT' },
        { key: 'method', label: 'METHOD' },
        { key: 'trade_status', label: 'STATUS' },
        { key: 'channel_order_id', label: 'PARTNER ID' },
        { key: 'channel_order_status', label: 'PARTNER STATUS' },
        { key: 'out_uid', label: 'MERCHANT UID' },
        { key: 'update_time', label: 'UPDATE TIME' },
      ],
      sanctionRecords: [],
      detailSanctionsTableFields: [
        'show_details',
        { key: 'last_name', label: 'Name' },
        { key: 'sdn_type', label: 'Type' },
        { key: 'program_list', label: 'Program(s)' },
        'actions',
      ],
      typeFilter: '1',
      typeOptions: [
        { label: 'SDN', value: '1' },
        { label: 'Non-SDN', value: '2' },
      ],
      idsTableFields: [
        'uid',
        'id_type',
        'id_number',
        'id_country',
        'issue_date',
        'expiration_date',
      ],
      akaTableFields: [
        'uid',
        'type',
        'category',
        'last_name',
        'first_name',
      ],
      addressTableFields: [
        'uid',
        'address1',
        'address2',
        'address3',
        'city',
        'state_or_province',
        'postal_code',
        'country',
      ],
      nationalityTableFields: [
        'uid',
        'country',
        'main_entry',
      ],
      citizenshipTableFields: [
        'uid',
        'country',
        'main_entry',
      ],
      dateBirthTableFields: [
        'uid',
        'date_of_birth',
        'main_entry',
      ],
      placeBirthTableFields: [
        'uid',
        'place_of_birth',
        'main_entry',
      ],
      shortcuts: [
        {
          text: 'Today',
          onClick() {
            const end = moment().endOf('day')
              .toDate()
            const start = moment().startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Yesterday',
          onClick() {
            const end = moment().subtract(1, 'days').endOf('day')
              .toDate()
            const start = moment().subtract(1, 'days').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last 7 Days',
          onClick() {
            const end = moment().endOf('day')
              .toDate()
            const start = moment().subtract(6, 'days').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'This Month',
          onClick() {
            const end = moment().endOf('month').endOf('day')
              .toDate()
            const start = moment().startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last Month',
          onClick() {
            const end = moment().subtract(1, 'month').endOf('month').endOf('day')
              .toDate()
            const start = moment().subtract(1, 'month').startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last 3 Months',
          onClick() {
            const end = moment().subtract(1, 'month').endOf('month').endOf('day')
              .toDate()
            const start = moment().subtract(3, 'month').startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
      ],
    }
  },
  watch: {
    createRange: function formatWithTz(object) {
      const startTime = object[0]
      const endTime = object[1]
      const startTzTime = moment.tz(startTime, this.tzFilter)
      const endTzTime = moment.tz(endTime, this.tzFilter)
      const startTimeStr = startTzTime.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss')
      const endTimeStr = endTzTime.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss')
      const dateArray = new Array(0)
      dateArray.push(startTimeStr, endTimeStr)
      this.$emit('update:createRange', dateArray)
    },
  },
  created() {
    this.createRange = [moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')]
    this.searchRequst()
    fetchIdInfo(
      this.searchRequest,
      result => {
        this.records = result.list
        this.info = result.info
        this.individualDetails = result.detail_info
        this.individualDetails.country = 'Brazil'
        this.individualBasic = result.basic_info
        this.sanctionRecords = result.sdn_entry_list
        this.sanctionBtnShow = result.show_sanction_btn
      },
      fail => {
        this.showAlert('warning', `Fetch record with ${fail}`)
      },
    )
    this.search()
  },
  methods: {
    searchRequst() {
      let [start, end] = this.createRange
      start = moment.tz(moment.tz(start, this.tzFilter), 'UTC').format('YYYY-MM-DD HH:mm:ss')
      end = moment.tz(moment.tz(end, this.tzFilter), 'UTC').format('YYYY-MM-DD HH:mm:ss')
      this.searchRequest.start_time = start
      this.searchRequest.end_time = end
      this.searchRequest.user_name = this.getUserName()
    },
    search() {
      this.searchRequst()
      fetchOrderIdInfoList(
        this.searchRequest,
        records => {
          this.records = records
          this.$bvToast.toast('Success fetching Transactions', {
            title: 'Success',
            icon: 'AlertTriangleIcon',
            variant: 'success',
            toaster: 'b-toaster-top-right',
            solid: true,
          })
        },
        fail => {
          this.showAlert('warning', `Fetch records with ${fail}`)
        },
      )
    },
    resolveStatusVariant(status) {
      switch (status) {
        case 'Processing':
          return 'primary'
        case 'Success':
          return 'success'
        case 'ChargeBack':
          return 'danger'
        case 'Refunded':
        case 'PartialRefund':
          return 'danger'
        case 'RiskControl':
        case 'Dispute':
          return 'warning'
        case 'Refused':
          return 'info'
        default:
          return 'secondary'
      }
    },
    getUserName() {
      const userString = localStorage.getItem('userData')
      if (userString !== null && userString !== undefined) {
        const user = JSON.parse(userString)
        return user.realName
      }
      return ''
    },
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
    confirmSanction(item, isSelected) {
      let params = null
      if (isSelected) {
        params = {
          id_no: this.info.id_no,
          id_type: this.info.id_type,
          name: this.info.user_name,
          sanction_id: item.uid,
        }
      } else {
        params = {
          id_no: this.info.id_no,
          id_type: this.info.id_type,
          name: this.info.user_name,
          sanction_id: 1,
        }
      }

      confirmSanction(
        params,
        () => {
          this.showAlert('success', 'sanction confirmed!')
        },
        fail => {
          this.showAlert('warning', `sanction confirmed fail with ${fail}`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.mx-datepicker-range {
  width: 100% !important;
}
.mx-input {
  height: 37px;
}
</style>
